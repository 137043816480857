import React from "react";

const OnlineOrderButton = () => {
  return (
    <div>
      <div className="bg-white text-primary text-sm rounded cursor-pointer w-32 h-10 flex items-center justify-center">
        <a href="https://overflo.leteatgo.ca/" className="cursor-pointer" target="_blank" rel="noopener noreferrer">Online order</a>
      </div>
    </div>
  );
};

export default OnlineOrderButton;
