import React from "react";
const google_api_key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const Contact = () => {
  return (
    <div className="flex flex-col bg-primary p-5 md:p-10 text-white" id="contact">
      <div className="text-2xl font-bold pb-4">Contact</div>
      <div className="flex flex-col sm:flex-row">
        <div className="flex flex-row space-x-10 pb-5 w-full md:w-1/2">
          <div className="w-full md:w-1/2">
            <hr className="my-2 w-8 border-2" />
            <div className="text-lg mb-2"> Contact us</div>
            <ul className="text-xs">
              <li className="my-2">
                14973 Marine Dr,
                <br />White Rock, BC <br />V4B 1C3, Canada
              </li>
              <li className="my-2"><a href="https://www.instagram.com/overflo_dessert_cafe/">@overflo_dessert_cafe</a></li>
              <li className="my-2">+1(778)987-1250</li>
            </ul>
          </div>
          <div className="w-full md:w-1/2">
            <hr className="my-2 w-8 border-2" />
            <div className="text-lg mb-2">Opening hour</div>
            <ul className="text-xs">
              <li className="my-2">Mon. 1:00pm - 11:00pm</li>
              <li className="my-2">Tue. 1:00pm - 11:00pm</li>
              <li className="my-2">Wed. 1:00pm - 11:00pm</li>
              <li className="my-2">Thu. 1:00pm - 11:00pm</li>
              <li className="my-2">Fri. 12:00pm - 12:00am</li>
              <li className="my-2">Sat. 12:00pm - 12:00am</li>
              <li className="my-2">Sun. 12:00pm - 12:00am</li>
            </ul>
          </div>
        </div>
        <div className="w-full md:w-1/2">
        <iframe 
        title="overflo location"
        src={`https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5232.782741446631!2d-122.807588!3d49.022163!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485c3b6cfa1245f%3A0x7bdaba5c3f9fd792!2sOVERFLO%20Dessert%20Cafe!5e0!3m2!1sen!2skr!4v1719972330705!5m2!1sen!2skr&key=${google_api_key}`}
        style={{ width: "100%", height: "250px" }}>
    </iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
