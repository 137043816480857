import React, { useState, useEffect } from "react";

const importAllImages = (r) => r.keys().map(r);
const images = importAllImages(require.context('../../assets/gallery/', false, /\.(png|jpe?g|svg)$/));

const shuffleArray = (array) => {
  let currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

const ImageBlock = ({ src, isMobile }) => (
  <div
    className={`bg-gray-200 ${
      isMobile ? "mw-64" : "mw-48"
    } h-48 rounded flex justify-center items-center overflow-hidden`}
    style={{ position: 'relative' }}
  >
    <img
      src={src}
      alt="Gallery"
      className="h-full w-full object-cover"
      style={{ objectPosition: 'center center' }}
    />
  </div>
);

const Gallery = () => {
  const [numOfImages, setNumOfImages] = useState(4);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [shuffledImages, setShuffledImages] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setNumOfImages(window.innerWidth >= 768 ? 8 : 4);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setShuffledImages(shuffleArray([...images]));
  }, []);

  const renderImageBlocks = (count) => {
    const imageBlocks = [];
    for (let i = 0; i < count; i++) {
      imageBlocks.push(
        <ImageBlock key={i} src={shuffledImages[i % shuffledImages.length]} isMobile={isMobile} />
      );
    }
    return imageBlocks;
  };

  return (
    <div className="p-5 md:p-10" id="gallery">
      <hr className="my-2 w-12 border-primary border-2" />
      <div className="text-2xl font-bold pb-8">Gallery of overflo.</div>
      <div className="p-4">
        <div className="grid grid-cols-2 pb-8 md:grid-cols-4 gap-4 md:p-0 h-auto justify-between">
          {renderImageBlocks(numOfImages)}
        </div>
      </div>
      <div className="flex justify-center p-8">
        <div className="bg-primary text-white px-4 py-2 rounded cursor-pointer w-40 text-center">
          <a href="https://www.instagram.com/overflo_dessert_cafe/" target="_blank" rel="noopener noreferrer">
            Go to Instagram
          </a>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
