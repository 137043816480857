  import React from "react";
  import MainImg from "../assets/main.png";

  const Header = () => {
    return (
      <div className="bg-cover bg-center" style={{ backgroundImage: `url(${MainImg})`,backgroundPosition: '0% 27%',width:'100%'}}>
        <div className="px-5 py-10 md:px-10 md:py-20 space-y-5">
          <div className="text-white font-bold md:text-4xl xs:px-10 py-10 md:py-20 md:pt-30">
            White Rock Dessert Cafe,<br/>
            Overflo.
          </div>
          
          <div className="bg-white text-primary rounded cursor-pointer w-32 h-10 flex   items-center justify-center">
            <a href="https://overflo.leteatgo.ca/" className="cursor-pointer" target="_blank" rel="noopener noreferrer">Online order</a>
          </div>
        </div>
      </div>
    );
  };

  export default Header;
