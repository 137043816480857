import React, { useState, useEffect } from "react";
import Img1 from "../../assets/img1.png";
import Img2 from "../../assets/img2.png";
import Img3 from "../../assets/img3.png";
import Img4 from "../../assets/img4.png";
import Img5 from "../../assets/img5.png";
import logo from  '../../assets/logo.png';
import webAbout from '../../assets/web_about.png';
import mobileAbout from '../../assets/mobile_about.png';
const About = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let about;
  if (windowWidth >= 640) {
    about = webAbout;
  } else {
    about = mobileAbout;
  }

  const contentData = [
    {
      imageUrl: Img1,
      title: "Coffee",
      content:
        "Coffee blended with fresh, high-quality coffee beans",
      alt: "Coffee",
    },
    {
      imageUrl: Img2,
      title: "Beverage",
      content:
        "Beverages crafted with fresh ingredients to deliver a rich taste.",
      alt: "Beverage",
    },
    {
      imageUrl: Img3,
      title: "Soft Icecream",
      content:
        "Made with the finest ingredients for smooth taste experience",
      alt: "Soft Icecream",
    },
    {
      imageUrl: Img4,
      title: "Dessert",
      content:
        "Carefully made sweet dessert that will fill a day with joy",
      alt: "Dessert",
    },
    {
      imageUrl: Img5,
      title: "Noodles",
      content:
        "Enjoy a variety of noodles. We've got everything you need!",
      alt: "Noodles",
    }
  ];

  return (
    <div id="about">
      <hr className="mt-14 ml-5 md:ml-10 mb-2 w-12 border-primary border-2 md:auto" />
      <div className="text-2xl ml-5 md:ml-10 font-bold">About overflo.</div>
      <div className="flex flex-col p-5 md:p-10 space-y-2 mb-12 md:justify-center">
        <div>
          <div className="flex flex-row mb-4">
            <div className="flex md:h-45 w-1/2 md:w-3/5 md:min-w-64 md:min-h-64">
              <img src={about} style={{weight: '100%'}} alt="dessert cafe overflo"/>
            </div>
            <div className="flex flex-col w-1/2 md:w-2/5">
              <div className="flex w-32 text-center ml-4 md:ml-5">
                <img src={logo} className="w-2/3 md:w-full" alt="overflo logo"/>
              </div>
              <div className="text-xs md:text-sm p-4 md:p-5 max-w-full max-w-1/5">
              Overflo is a peaceful and atmospheric café located in White Rock, Canada. <br /> <br/> 
              At Overflo, you can enjoy a variety of desserts and beverages while savoring the deep aroma of coffee in the peaceful surroundings
of White Rock.
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 pb-8 md:grid-cols-4 gap-4 md:p-0 h-auto justify-between">
          {contentData.map((data, index) => (
            <div className="w-full text-center md:rounded-tl-3xl md:rounded-br-3xl border sm:shadow-lg" key={index}>
              <div className="md:rounded-tl-3xl md:h-45 lg:h-60 overflow-hidden flex items-center justify-center" ><img className="" src={data.imageUrl} style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt={data.alt}/></div>
              <div className="text-center bg-white md:rounded-br-3xl">
                <div className="pt-2">{data.title}</div>
                <div className="text-sm p-2">{data.content}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;
