import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer/Footer";
import Contact from "./components/Footer/Contact";
import About from "./components/Body/About";
import Gallery from "./components/Body/Gallery";
import OnlineOrderBanner from "./components/Body/OnlineOrderBanner";

function App() {
  return (
    <div>
      <Router>
        <Navbar />
        <Header />
        <About />
        <OnlineOrderBanner />
        <Gallery />
        <Contact />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
