import React from "react";
import OnlineOrderButton from "./OnlineOrderButton";
import emoji from "../../assets/emoji.png";
const OnlineOrderBanner = () => {
  return (
    <div className="w-full flex flex-row bg-primary ">
    <div className="w-1/4 md:w-1/3 py-12 pl-5 md:p-10">
        <img src={emoji} alt="emoticon"/>
    </div>
    <div className="w-3/4 md:w-2/3 flex flex-col justify-center text-white py-10 pr-5 md:p-10">
      <div className="text-right flex flex-col justify-end">
        <div className="font-bold text-right md:text-xl pb-4">
          Order online,
          <br/>
          Get your food ready right away
        </div>
        <div className="flex justify-end">
        <OnlineOrderButton />
      </div>
      </div>
    </div>
    </div>
  );
};

export default OnlineOrderBanner;
