import React from "react";
import { Link } from "react-scroll"; // Assuming you're using React Router
import logo from  '../assets/logo.png';
const Navbar = () => {

  return (
    <div>
      <div className="flex flex-wrap items-center justify-between align-middle mx-auto h-15 pl-5 pr-5 md:pr-10 md:pl-10 p-4">
        <div className="py-2 rounded cursor-pointer">
          <Link to="home"><img src={logo} style={{width: '100px', height: '100%'}} alt="overflo logo"/></Link>
        </div>
        <div className="flex flex-row space-x-5">
          <div className="hidden md:flex items-center justify-between flex-row space-x-5 list-none text-sm">
            <li className="cursor-pointer">
              <Link to="home" smooth={true}>
                Home
              </Link>
            </li>
            <li className="cursor-pointer">
              <Link to="about" smooth={true}>
                About
              </Link>
            </li>
            <li className="cursor-pointer">
              <Link
                to="gallery"
                smooth={true}
                spy={true}
                offset={50}
                duration={500}
              >
                Gallery
              </Link>
            </li>
            <li className="cursor-pointer">
              <Link to="contact" smooth={true}>
                Contact
              </Link>
            </li>
          </div>
          <a href="https://overflo.leteatgo.ca/" className="cursor-pointer" target="_blank" rel="noopener noreferrer">
            <div className="bg-primary text-white px-4 py-2 rounded cursor-pointer">
              Online order
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
